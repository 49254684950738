.App {
  text-align: center;

}
body{
  height: 100%;
  margin:0;
  padding:0;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
background-attachment: fixed;
  background-image: url("./images/fondo2.jpg");
}



.App-header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}


.contenedorTitulo{
  width: 100%;
  text-align: center;
}
.contenedorSubtitulo {
  text-align: center;
  height: 80vh;
}
img {
  height: auto;
  max-width: 100%;
}
#titulo{
  font-size: 50px;
  font-weight: 100;
}
#subtitulo{
  font-size: 30px
}
#imagenContenedor{
  display: none;
}
#marginContenedorSubtitulo{
  margin-top:60%
}
#margincontenedorTitulo{
  margin-top:4%;
  letter-spacing: 6px
}

@media only screen and (max-width: 490px) {
  body {
    height: auto;
    width: 100%;
    padding: 30px;
    background: #0e0b0b;
    /*background-image: url("./images/fondoblanco.png");*/
  }
  #imagenContenedor{
    display: block;
    height: 13vh;
  }
  #margincontenedorTitulo{
    margin-top:21%;
    letter-spacing: 6px
  }
  .contenedorSubtitulo {
    text-align: center;
    height: 40vh;
  }
  #subtitulo{
    font-size: 15px
  }
  #titulo{
    font-size: 20px;
  }
}

@media (min-width: 491px) and (max-width: 700px){
  body {
    height: auto;
    width: 100%;
    padding: 30px;
    background: #0e0b0b;
    /*background-image: url("./images/fondoblanco.png");*/
  }
  #imagenContenedor{
    display: block;
    height: 20vh;
  }
  #marginContenedorSubtitulo {
    margin-top: 49%;
  }
  #margincontenedorTitulo{
    margin-top: 40%;
    letter-spacing: 6px
  }
  .contenedorSubtitulo {
    text-align: center;
    height: 30vh;
  }
  #subtitulo{
    font-size: 18px
  }
  #titulo{
    font-size: 30px;
  }
}

/*@media (min-width: 700px) {*/
/*  #marginContenedorSubtitulo {*/
/*    margin-top: 74%;*/
/*    font-size: 28px;*/
/*  }*/
/*  #margincontenedorTitulo{*/
/*    margin-top: 20%;*/
/*    letter-spacing: 6px*/
/*  }*/
/*  .contenedorSubtitulo {*/
/*    text-align: center;*/
/*    height: 67vh;*/
/*  }*/
/*}*/